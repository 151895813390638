import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, ReportControlGroupItemDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-report-control-group-item-item-form',
  templateUrl: './report-control-group-item-item-form.component.html',
  styleUrl: './report-control-group-item-item-form.component.scss',
})
export class ReportControlGroupItemItemFormComponent extends BaseForm<ReportControlGroupItemDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService,ModuleKeywords.ReportControlGroupItem);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
